import * as React from "react"
import { graphql } from 'gatsby'

// markup
const WorkoutPage = (props) => {
  const workout = props.data.workout
  
  return (
    <main>
      <h1>{workout.title} {workout.trainer ? `(by ${workout.trainer.firstName})` : ''}</h1>
    </main>
  )
}

export default WorkoutPage

export const query = graphql`
# $trainerId is pased via "context" in gatsby-node.js using exports.createPages
query($id: String) {
  workout(id: {eq: $id}) {
    id
    exercises
    title
    createdBy
    trainer {
      firstName
    }
  }
}
`